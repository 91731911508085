/* src/App.css */

body {
  background-color: #f8f9fa;
}

.hero {
  background-image: url("assets/images/header-1.jpg");
  background-size: cover;
  background-position: center;
  color: white;
  padding: 100px 0;
  height: 60vh;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.55); /* Semi-transparent black overlay */
  z-index: 1;
}

.hero .overlay {
  position: relative;
  z-index: 2;
  max-width: 600px;
}

.hero h1 {
  font-size: 3rem;
  font-weight: bold;
  color: white;
}

.hero p {
  font-size: 1.5rem;
}

section {
  padding: 50px 0;
}

footer {
  background-color: #343a40;
  color: white;
  padding: 50px 0;
}

footer h2 {
  margin-bottom: 30px;
}

footer form {
  max-width: 600px;
  margin: 0 auto;
}

footer p {
  margin: 0;
}

hero {
  height: 95vh;
}

.container {
  max-width: 700px !important;
}

section {
  padding: 50px 0;
}

.debug-border {
  border: 2px dashed red;
}

.kiosk-image {
  width: 50%; /* Adjust the size as needed */
  height: auto;
  margin: 0 auto; /* Center the image */
}

.kiosk-image-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -80px; /* Adjust the top margin as needed */
  margin-bottom: -50px;
}

/* Media Queries for Tablets */
@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem;
  }

  .hero p {
    font-size: 1.25rem;
  }

  .hero .btn {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }

  .call-to-action {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .call-to-action .btn {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    display: block; /* Make buttons stack vertically */
    width: 80%; /* Make buttons full width */
    margin-bottom: 0.5rem; /* Add space between buttons */
  }

  .kiosk-image {
    width: 60%; /* Adjust the size for tablets */
  }
}

/* Media Queries for Mobile Devices */
@media (max-width: 576px) {
  .hero h1 {
    font-size: 2rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .hero .btn {
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
    width: 90%; /* Make buttons full width */
    margin-bottom: 0.5rem; /* Add space between buttons */
  }

  .call-to-action {
    display: flex;
    flex-direction: column !important;
    align-items: center;
  }

  .call-to-action .btn {
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
    display: block; /* Make buttons stack vertically */
    width: 100%; /* Make buttons full width */
    margin-bottom: 0.5rem; /* Add space between buttons */
  }

  .kiosk-image {
    width: 80%; /* Adjust the size for mobile devices */
  }
}
